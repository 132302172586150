<template>
  <div class="test-results-page">
    <v-row justify="center">
      <v-col md="6">
        <div>
          <intract-smart-list
            class="mt-2"
            ref="test-results-smart-list"
            :endpoint="testResultsEndpoint"
            paginated
            :filter-fields="filterFields"
          >
            <template v-slot:custom-filter--dateRange>
              <v-dialog
                ref="dialog"
                v-model="modal"
                :return-value.sync="date"
                persistent
                width="290px"
              >
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    solo
                    clearable
                    @click:clear="date = null"
                    :value="formattedDateRangeString"
                    label="Filter by Date"
                    prepend-inner-icon="mdi-calendar"
                    readonly
                    hide-details="auto"
                    v-bind="attrs"
                    v-on="on"
                  ></v-text-field>
                </template>
                <v-date-picker v-model="date" range>
                  <v-row no-gutters>
                    <v-col cols="12" class="text-center">
                      <span
                        v-if="date && date.length == 1"
                        class="text-caption red--text"
                        >Please select End Date as well</span
                      >
                    </v-col>
                    <v-col class="text-right">
                      <v-btn text color="primary" @click="modal = false">
                        Cancel
                      </v-btn>
                      <v-btn
                        text
                        :disabled="date && date.length == 1"
                        color="primary"
                        @click="$refs.dialog.save(date)"
                      >
                        OK
                      </v-btn>
                    </v-col>
                  </v-row>
                </v-date-picker>
              </v-dialog>
            </template>
            <template v-slot:list-item="{ item: result }">
              <v-card class="mb-2 mx-3">
                <v-list-item
                  @click="
                    currentUser.is_student
                      ? {}
                      : $set(result, 'expanded', !result.expanded)
                  "
                >
                  <v-list-item-content>
                    <v-list-item-subtitle class="primary--text"
                      >{{ result.subject.name }} |
                      {{ result.subject.room.sections }}</v-list-item-subtitle
                    >
                    <v-list-item-title class="text-wrap">{{
                      result.tname
                    }}</v-list-item-title>
                    <v-list-item-title
                      v-if="currentUser.is_student"
                      class="font-weight-bold primary--text text-wrap"
                      >{{ result.result ? result.result.scored : 0 }}/{{
                        result.total_marks
                      }}
                      Scored</v-list-item-title
                    >
                    <v-list-item-subtitle
                      v-if="currentUser.is_student && result.result == null"
                      class="red--text font-weight-bold"
                      >Not attempted!</v-list-item-subtitle
                    >
                    <v-list-item-subtitle class="caption text-wrap"
                      ><span
                        v-if="
                          moment(result.startsAt).date() ==
                          moment(result.endsAt).date()
                        "
                        >{{ moment(result.startsAt).format("lll") }} -
                        {{ moment(result.endsAt).format("hh:mm A") }}</span
                      ></v-list-item-subtitle
                    >
                  </v-list-item-content>
                  <v-list-item-action>
                    <v-row no-gutters>
                      <v-btn
                        small
                        v-if="currentUser.is_student && result.result != null"
                        color="primary"
                        @click="handleViewButtonClicked(result)"
                        >View Result
                        <v-icon class="ml-1" small>mdi-arrow-right</v-icon>
                      </v-btn>
                      <div v-if="!currentUser.is_student">
                        <v-btn small icon color="primary">
                          <v-icon class="ml-1" v-if="result.expanded"
                            >mdi-arrow-up-drop-circle-outline</v-icon
                          >
                          <v-icon class="ml-1" v-else
                            >mdi-arrow-down-drop-circle-outline</v-icon
                          >
                        </v-btn>
                      </div>
                    </v-row>
                  </v-list-item-action>
                </v-list-item>
                <div v-if="result.expanded">
                  <v-divider></v-divider>
                  <v-list-item>
                    <v-list-item-content>
                      <v-list-item-title class="text-wrap"
                        >Show Results?</v-list-item-title
                      >
                      <v-list-item-subtitle class="text-wrap"
                        >If enabled, results in the <b>Results</b> section will
                        be visible to students who have appeared for the
                        test</v-list-item-subtitle
                      >
                    </v-list-item-content>
                    <v-list-item-action class="non-focused">
                      <v-checkbox
                        blur
                        class="non-focused"
                        v-model="result.showResult"
                        @click="toggleShowResults(result)"
                      ></v-checkbox>
                    </v-list-item-action>
                  </v-list-item>
                  <v-divider></v-divider>

                  <v-card-actions
                    ><v-spacer></v-spacer
                    ><v-btn color="primary" text @click="viewReport(result)">
                      View Reports
                    </v-btn></v-card-actions
                  >
                </div>
              </v-card>
            </template>
          </intract-smart-list>
        </div>
      </v-col>
    </v-row>
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import moment from "moment";
export default {
  name: "TestResults",
  mixins: [Mixins.essentials, Mixins.pullToRefresh],
  components: {
    IntractSmartList,
  },
  data() {
    return {
      moment,
      date: null,
      modal: false,
      roomId: this.$route.params.roomId,
    };
  },
  computed: {
    formattedDateRangeString() {
      if (!this.date) return null;
      if (this.date.length == 0) return "";
      else if (this.date.length == 1)
        return moment(this.date[0]).format("ll") + " - ";
      else {
        return (
          moment(this.date[0]).format("ll") +
          " - " +
          moment(this.date[1]).format("ll")
        );
      }
    },
    testResultsEndpoint() {
      var dateFilter = null;
      // if date filter added then homework should be shown of that period only
      if (this.date && this.date.length == 2) {
        // dateFilter = "startsAt__gte=" + moment().format(this.Helper.dateURLFormat);
        var startDate,
          endDate = null;
        if (moment(this.date[0]).isSameOrBefore(moment(this.date[1]))) {
          startDate = this.date[0];
          endDate = this.date[1];
        } else {
          startDate = this.date[1];
          endDate = this.date[0];
        }
        startDate = moment(startDate + ' ' + '0:01').format(this.Helper.dateURLFormat);
        endDate = moment(endDate + ' ' + '23:59').format(this.Helper.dateURLFormat);
        dateFilter = `startsAt__gte=${startDate}&startsAt__lte=${endDate}`;
      }
      var url = this.Helper.addUrlParams(this.endpoints.onlineTestViewSet, [
        "include_results=true",
        `endsAt__lte=${moment().format(this.Helper.dateURLFormat)}`,
        "ordering=-startsAt",
      ]);
      if (dateFilter) {
        url = this.Helper.addUrlParams(url, dateFilter);
      }
      if (this.currentUser.is_student)
        url = this.Helper.addUrlParams(url, "showResult=true"); // only show results whose show result is true
      if (this.currentUser.is_admin || this.currentUser.is_student)
        url = this.Helper.addUrlParams(url, ["subject__room=" + this.roomId]);
      else if (this.currentUser.is_faculty)
        url = this.Helper.addUrlParams(url, [
          "subject__faculty__id=" + this.currentUser.id,
        ]);
      return url;
    },
    subjectsEndpoint() {
      if (this.currentUser.is_student || this.currentUser.is_admin)
        return this.Helper.addUrlParams(
          this.endpoints.subjectViewSet,
          "room=" + this.roomId
        );
      // if faculty
      return this.Helper.addUrlParams(
        this.endpoints.subjectViewSet,
        "faculty=" + this.currentUser.id
      );
    },
    filterFields() {
      if (!this.subjectsEndpoint) return {};
      return {
        subject: {
          model: null,
          label: "Filter by Subject",
          endpoint: this.subjectsEndpoint,
          itemSubtitle: "room.sections",
          itemValue: "id",
          multiple: true,
          itemText: "name",
          param: "subject__in",
          //   dynamicParam: true
        },

        dateRange: {
          model: null,
          items: [],
          label: "Filter by Date",
          // itemValue: "id",
          custom: true,
          // itemText: "name",
          // param: "subject__in",
          //   dynamicParam: true
        },

        // user__room: {
        //   model: null,
        //   items: this.allRooms,
        //   label: "Filter by Classroom",
        //   itemValue: "id",
        //   itemText: "sections",
        //   param: "user__room__in",
        // },
      };
    },
  },
  methods: {
    async viewReport(test) {
      var url = `${this.endpoints.baseTestsUrl}test/${test.id}/stats`;
      this.Helper.openUrl(url);
    },

    async toggleShowResults(test) {
      var url = this.endpoints.onlineTestViewSet + test.id + "/";
      var response = await this.api.call(
        this.essentials,
        url,
        this.api.Methods.PATCH,
        { showResult: test.showResult }
      );
      if (response) {
        this.showSnackbar({
          title: response.showResult
            ? "Results are now being shown!"
            : "Results successfully hidden",
          type: "success",
        });
      }
    },
    async handleViewButtonClicked(test) {
      if (this.currentUser.is_student)
        this.Helper.openUrl(
          this.endpoints.baseTestsUrl +
            `test/${test.id}/stats/student/${this.currentUser.id}`
        );
      // show self result if student
      else if (this.currentUser.is_admin || this.currentUser.is_faculty)
        this.Helper.openUrl(
          this.endpoints.baseTestsUrl + `test/${test.id}/stats`
        ); // show full test reports if teacher
    },

    async onRefresh() {
      console.log(this.$refs);
      if (this.$refs["test-results-smart-list"])
        this.$refs["test-results-smart-list"].getItems();
    },
  },
};
</script>