var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"test-results-page"},[_c('v-row',{attrs:{"justify":"center"}},[_c('v-col',{attrs:{"md":"6"}},[_c('div',[_c('intract-smart-list',{ref:"test-results-smart-list",staticClass:"mt-2",attrs:{"endpoint":_vm.testResultsEndpoint,"paginated":"","filter-fields":_vm.filterFields},scopedSlots:_vm._u([{key:"custom-filter--dateRange",fn:function(){return [_c('v-dialog',{ref:"dialog",attrs:{"return-value":_vm.date,"persistent":"","width":"290px"},on:{"update:returnValue":function($event){_vm.date=$event},"update:return-value":function($event){_vm.date=$event}},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"solo":"","clearable":"","value":_vm.formattedDateRangeString,"label":"Filter by Date","prepend-inner-icon":"mdi-calendar","readonly":"","hide-details":"auto"},on:{"click:clear":function($event){_vm.date = null}}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.modal),callback:function ($$v) {_vm.modal=$$v},expression:"modal"}},[_c('v-date-picker',{attrs:{"range":""},model:{value:(_vm.date),callback:function ($$v) {_vm.date=$$v},expression:"date"}},[_c('v-row',{attrs:{"no-gutters":""}},[_c('v-col',{staticClass:"text-center",attrs:{"cols":"12"}},[(_vm.date && _vm.date.length == 1)?_c('span',{staticClass:"text-caption red--text"},[_vm._v("Please select End Date as well")]):_vm._e()]),_c('v-col',{staticClass:"text-right"},[_c('v-btn',{attrs:{"text":"","color":"primary"},on:{"click":function($event){_vm.modal = false}}},[_vm._v(" Cancel ")]),_c('v-btn',{attrs:{"text":"","disabled":_vm.date && _vm.date.length == 1,"color":"primary"},on:{"click":function($event){return _vm.$refs.dialog.save(_vm.date)}}},[_vm._v(" OK ")])],1)],1)],1)],1)]},proxy:true},{key:"list-item",fn:function(ref){
var result = ref.item;
return [_c('v-card',{staticClass:"mb-2 mx-3"},[_c('v-list-item',{on:{"click":function($event){_vm.currentUser.is_student
                    ? {}
                    : _vm.$set(result, 'expanded', !result.expanded)}}},[_c('v-list-item-content',[_c('v-list-item-subtitle',{staticClass:"primary--text"},[_vm._v(_vm._s(result.subject.name)+" | "+_vm._s(result.subject.room.sections))]),_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v(_vm._s(result.tname))]),(_vm.currentUser.is_student)?_c('v-list-item-title',{staticClass:"font-weight-bold primary--text text-wrap"},[_vm._v(_vm._s(result.result ? result.result.scored : 0)+"/"+_vm._s(result.total_marks)+" Scored")]):_vm._e(),(_vm.currentUser.is_student && result.result == null)?_c('v-list-item-subtitle',{staticClass:"red--text font-weight-bold"},[_vm._v("Not attempted!")]):_vm._e(),_c('v-list-item-subtitle',{staticClass:"caption text-wrap"},[(
                        _vm.moment(result.startsAt).date() ==
                        _vm.moment(result.endsAt).date()
                      )?_c('span',[_vm._v(_vm._s(_vm.moment(result.startsAt).format("lll"))+" - "+_vm._s(_vm.moment(result.endsAt).format("hh:mm A")))]):_vm._e()])],1),_c('v-list-item-action',[_c('v-row',{attrs:{"no-gutters":""}},[(_vm.currentUser.is_student && result.result != null)?_c('v-btn',{attrs:{"small":"","color":"primary"},on:{"click":function($event){return _vm.handleViewButtonClicked(result)}}},[_vm._v("View Result "),_c('v-icon',{staticClass:"ml-1",attrs:{"small":""}},[_vm._v("mdi-arrow-right")])],1):_vm._e(),(!_vm.currentUser.is_student)?_c('div',[_c('v-btn',{attrs:{"small":"","icon":"","color":"primary"}},[(result.expanded)?_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-arrow-up-drop-circle-outline")]):_c('v-icon',{staticClass:"ml-1"},[_vm._v("mdi-arrow-down-drop-circle-outline")])],1)],1):_vm._e()],1)],1)],1),(result.expanded)?_c('div',[_c('v-divider'),_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-title',{staticClass:"text-wrap"},[_vm._v("Show Results?")]),_c('v-list-item-subtitle',{staticClass:"text-wrap"},[_vm._v("If enabled, results in the "),_c('b',[_vm._v("Results")]),_vm._v(" section will be visible to students who have appeared for the test")])],1),_c('v-list-item-action',{staticClass:"non-focused"},[_c('v-checkbox',{staticClass:"non-focused",attrs:{"blur":""},on:{"click":function($event){return _vm.toggleShowResults(result)}},model:{value:(result.showResult),callback:function ($$v) {_vm.$set(result, "showResult", $$v)},expression:"result.showResult"}})],1)],1),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"primary","text":""},on:{"click":function($event){return _vm.viewReport(result)}}},[_vm._v(" View Reports ")])],1)],1):_vm._e()],1)]}}])})],1)])],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }